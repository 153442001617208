<template>
  <div class="page-content">
    <el-row class="mb-16">
      <el-col :span="24" class="u-flex u-row-between">
        <div class="sertch inline">
          <el-input v-model="listQuery.KeyWord" placeholder="请输入姓名...">
            <el-button
              slot="append"
              type="primary"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-table
      border
      v-loading="listLoading"
      :data="list"
      stripe
      style="width: 100%"
      height="640px"
    >
      <el-table-column width="" label="姓名" prop="Name" />
      <el-table-column width="" label="学号" prop="Code" />
      <el-table-column width="" label="性别" prop="SexStr" />
      <el-table-column width="" label="专业" prop="MajorName" />
      <el-table-column width="" label="班级" prop="ClassName">
        <template slot-scope="scope">
          <span>{{ scope.row.Grade + " " + scope.row.ClassName }}</span>
        </template>
      </el-table-column>
      <el-table-column width="" label="掌握能力" prop="AbilityCount">
        <template slot-scope="scope">
          <span>{{ scope.row.AbilityCount || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            icon="el-icon-s-promotion"
            @click="showAbility(scope.row)"
            >能力</el-button
          >
          <el-button
            type="text"
            size="mini"
            icon="el-icon-s-cooperation"
            @click="showRecord(scope.row)"
            >档案</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="u-flex u-row-right"
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.PageIndex"
      :limit.sync="listQuery.PageSize"
      @pagination="getList"
    />

    <el-dialog title="能力" :visible.sync="abilityDialogVisible">
      <el-form>
        <el-form-item label="能力" label-width="100px">
          <el-cascader
            v-model="StudentAbilityList"
            :options="AbilityIdOptions"
            :show-all-levels="true"
            :props="AbilityIdProps"
            :style="{ width: '100%' }"
            placeholder="请选择掌握能力"
            @change="abilityChange"
            clearable
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="abilityDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAbility">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="档案" :visible.sync="recordDialogVisible">
      <div>档案</div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="recordDialogVisible = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination
import contentTools from "@/mixins/content-tools";
import { mapState } from "vuex";
import {
  getPageList,
  getStudentAbilityList,
  updateStudentAbility
} from "@/api/student";
import { arrayToTree } from "@/utils/util";
export default {
  name: "ListSchoolStudent",
  components: {
    Pagination
  },
  computed: {
    ...mapState("common", ["Ability"])
  },
  mixins: [contentTools],
  data() {
    return {
      abilityDialogVisible: false,
      StudentAbilityList: [],
      AbilityIdOptions: [],
      AbilityIdProps: {
        multiple: true,
        label: "Name",
        value: "Id",
        children: "Children",
        multiple: true,
        checkStrictly: false,
        emitPath: false,
        expandTrigger: "hover"
      },
      currentStudentId: "",
      recordDialogVisible: false,
      list: [],
      total: 0,
      listLoading: false,
      listQuery: {
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10
      }
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.listLoading = true;
        await this.getList();
        this.AbilityIdOptions = arrayToTree(this.Ability);
      } catch (err) {
      } finally {
        this.listLoading = false;
      }
    },
    async getList() {
      const { Result } = await getPageList(this.listQuery);
      this.list = Result.Data;
      this.total = Result.TotalCount;
    },
    abilityChange() {},
    showAbility({ Id }) {
      this.currentStudentId = Id;
      this.StudentAbilityList = [];
      getStudentAbilityList(Id).then(({ Result = [] }) => {
        this.StudentAbilityList = Result.map(item => item.AbilityContentId);
      });
      this.abilityDialogVisible = true;
    },
    submitAbility() {
      updateStudentAbility(
        this.currentStudentId,
        this.StudentAbilityList.map(item => ({ AbilityContentId: item }))
      ).then(({ Result = [] }) => {
        this.list.forEach(item => {
          if (item.Id === this.currentStudentId) {
            item.AbilityCount = Result.length;
            this.abilityDialogVisible = false;
          }
        });
      });
    },
    showRecord(id) {
      // this.recordDialogVisible = true;
      this.$router.push("/school/student/record?id=" + id.Id);
    }
  }
};
</script>

<style scoped>
.list_image {
  max-height: 40px;
  /* max-width: 100px; */
  display: block;
  margin: 0;
}
</style>
